'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

(function () {
    var MAP = void 0;
    var HOTEL_PIN = void 0;
    var iconDefault = SITE_FILES + 'svg/Map-Pin.svg';
    var iconHover = SITE_FILES + 'svg/Map-Hover-Pin.svg';

    if (document.querySelector("body#things-to-do") !== null) {

        /*
        * HELPERS
        ********************************************************************************/
        /**
         * SELECTORS
         */
        var PopulateSelectors = function PopulateSelectors(el, data, key) {
            var selector = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "Select an Option";

            var allKeys = Object.values(data).map(function (value) {
                return value[key];
            }),
                keys = allKeys.filter(function (item, index) {
                return allKeys.indexOf(item) >= index;
            });

            //SORT ALPHABETICALLY
            keys.sort(function (a, b) {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            });

            var markup = '<option value=\'\'>' + selector + '</option>';
            keys.forEach(function (el) {
                return markup += el !== "" ? '<option value=\'' + el + '\'>' + el + '</option>' : "";
            });
            el.innerHTML = markup;
        };

        /**
         * MARKUP
         */


        var GetAttractionsMarkup = function GetAttractionsMarkup(attractions) {
            var markup = '';
            attractions.forEach(function (attraction) {
                return markup += getAttractionMarkUp(attraction);
            });

            ATTRACTIONS_WRAPPER.innerHTML = markup;
            var getAllAttractionList = document.querySelectorAll("article[data-attraction]");
            getAllAttractionList.forEach(function (element) {
                return element.addEventListener("click", function (event) {
                    return OpenLightbox(element);
                }, false);
            });
        };

        var matchAttractionWithMarkers = function matchAttractionWithMarkers() {

            var tmpGetAttractionFromList = document.querySelectorAll("article[data-attraction]");

            var getAttractionFromList = {};
            tmpGetAttractionFromList.forEach(function (itemAttr) {
                var titleAttrBox = itemAttr.getAttribute('data-name');
                getAttractionFromList[titleAttrBox] = itemAttr;
            });

            GoogleMapsMarkers.forEach(function (itemMarker) {

                var titleMarker = itemMarker.title;

                if (getAttractionFromList.hasOwnProperty(titleMarker)) {
                    var attractionBox = getAttractionFromList[titleMarker];

                    var nameMarkersOnHover = '<div class="nameMarker">' + itemMarker.title + '</div>';

                    var infowindows = new google.maps.InfoWindow({
                        content: nameMarkersOnHover
                    });

                    attractionBox.addEventListener('mouseover', function () {
                        itemMarker.setIcon(iconHover);
                        infowindows.open(MAP, itemMarker);
                    });

                    attractionBox.addEventListener('mouseout', function () {
                        itemMarker.setIcon(iconDefault);
                        infowindows.close(MAP, itemMarker);
                    });
                }
            });
        };

        var getAttractionMarkUp = function getAttractionMarkUp(attraction) {
            var id = attraction.id,
                name = attraction.name,
                thumb = attraction.thumb,
                description = attraction.description,
                address = attraction.address,
                distance = attraction.distance,
                walking = attraction.walking,
                biking = attraction.biking,
                driving = attraction.driving;


            var getNameUrlImagethumb = thumb[0].thumbnail.split('/');
            var checkAmountUrl = getNameUrlImagethumb[getNameUrlImagethumb.length - 1];
            var imageAttraction = checkAmountUrl.length > 11 ? '' + thumb[0].thumbnail : 'https://via.placeholder.com/480x300';

            var iconMiles = '<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20"><path fill-rule="evenodd" d="M5.987.013c-3.317 0-6 2.979-6 6.663 0 5 6 12.373 6 12.373s6-7.376 6-12.373c0-3.684-2.683-6.663-6-6.663zm0 9.719c-1.68377792-.01653003-3.03692794-1.39198067-3.02593487-3.07580384C2.9720582 4.97237299 4.34305176 3.6147073 6.02690198 3.6201633 7.71075219 3.62561929 9.0729189 4.99214095 9.073 6.676c-.0093651 1.69561238-1.39037704 3.06319908-3.086 3.056z"/></svg>';
            var milesInfo = '<span>' + (distance + ' ' + 'Miles') + '</span>';

            var iconWalking = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.797 8.906l-2.813 14.109h2.109l1.828-8.016 2.063 2.016v6h2.016v-7.5l-2.109-2.016 0.609-3c1.313 1.5 3.281 2.484 5.484 2.484v-1.969c-1.922 0-3.469-1.031-4.266-2.438l-1.031-1.594c-0.422-0.609-0.984-0.984-1.688-0.984-0.281 0-0.516 0.094-0.797 0.094l-5.203 2.203v4.688h2.016v-3.375l1.781-0.703zM13.5 5.484c-1.078 0-2.016-0.891-2.016-1.969s0.938-2.016 2.016-2.016 2.016 0.938 2.016 2.016-0.938 1.969-2.016 1.969z"/></svg>';
            var walkingInfo = '<span>' + (walking + ' ' + 'Minutes Walking') + '</span>';

            var iconBiking = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.984 20.484c1.922 0 3.516-1.547 3.516-3.469s-1.594-3.516-3.516-3.516-3.469 1.594-3.469 3.516 1.547 3.469 3.469 3.469zM18.984 12c2.813 0 5.016 2.203 5.016 5.016s-2.203 4.969-5.016 4.969-4.969-2.156-4.969-4.969 2.156-5.016 4.969-5.016zM10.781 10.5l2.203 2.297v6.188h-1.969v-4.969l-3.234-2.813c-0.422-0.281-0.563-0.797-0.563-1.406 0-0.516 0.141-0.984 0.563-1.406l2.813-2.813c0.281-0.422 0.797-0.563 1.406-0.563s1.078 0.141 1.594 0.563l1.922 1.922c0.891 0.891 2.063 1.5 3.563 1.5v2.016c-2.109 0-3.75-0.797-5.063-2.109l-0.797-0.797zM5.016 20.484c1.922 0 3.469-1.547 3.469-3.469s-1.547-3.516-3.469-3.516-3.516 1.594-3.516 3.516 1.594 3.469 3.516 3.469zM5.016 12c2.813 0 4.969 2.203 4.969 5.016s-2.156 4.969-4.969 4.969-5.016-2.156-5.016-4.969 2.203-5.016 5.016-5.016zM15.516 5.484c-1.078 0-2.016-0.891-2.016-1.969s0.938-2.016 2.016-2.016 1.969 0.938 1.969 2.016-0.891 1.969-1.969 1.969z"/></svg>';
            var bikingInfo = '<span>' + (biking + ' ' + 'Minutes Biking') + '</span>';

            var iconDriving = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.016 11.016h13.969l-1.5-4.5h-10.969zM17.484 15.984c0.844 0 1.5-0.656 1.5-1.5s-0.656-1.5-1.5-1.5-1.5 0.656-1.5 1.5 0.656 1.5 1.5 1.5zM6.516 15.984c0.844 0 1.5-0.656 1.5-1.5s-0.656-1.5-1.5-1.5-1.5 0.656-1.5 1.5 0.656 1.5 1.5 1.5zM18.938 6l2.063 6v8.016c0 0.563-0.422 0.984-0.984 0.984h-1.031c-0.563 0-0.984-0.422-0.984-0.984v-1.031h-12v1.031c0 0.563-0.422 0.984-0.984 0.984h-1.031c-0.563 0-0.984-0.422-0.984-0.984v-8.016l2.063-6c0.188-0.609 0.797-0.984 1.453-0.984h10.969c0.656 0 1.266 0.375 1.453 0.984z"/></svg>';
            var drivingInfo = '<span>' + (driving + ' ' + 'Minutes Driving') + '</span>';

            var markup = '<article data-attraction="' + id + '" class="attraction" data-name="' + name + '">\n                            <div class="attraction--wrapper">\n                                <figure class="attraction-image">\n                                    <img src="' + imageAttraction + '" alt="' + (thumb[0].alt !== 'null' ? thumb[0].alt : 'placeholder image') + '">\n                                </figure>\n                                <div class="info-wrapper">\n                                    <h2>' + name + '</h2>\n                                    <p>' + address + '</p>\n                                    <p class="description">' + description + '</p>\n                                    ' + (distance.length > 0 || walking.length > 0 || biking.length > 0 || driving.length > 0 ? '<ul class="additional-info">' : '') + '\n                                        ' + (distance.length > 0 ? '<li>' + iconMiles + ' ' + milesInfo + '</li>' : '') + '\n                                        ' + (walking.length > 0 ? '<li>' + iconWalking + ' ' + walkingInfo + '</li>' : '') + '\n                                        ' + (biking.length > 0 ? '<li>' + iconBiking + ' ' + bikingInfo + '</li>' : '') + '\n                                        ' + (driving.length > 0 ? '<li>' + iconDriving + ' ' + drivingInfo + '</li>' : '') + '\n                                    ' + (distance.length > 0 || walking.length > 0 || biking.length > 0 || driving.length > 0 ? '</ul>' : '') + '\n                                    <a class="learn-more-btn btn white"><span>learn more</span></a> \n                                </div>\n                            </div>\n                        </article>';

            return markup;
        };

        //this will appear when the filter does not find any content


        /*
        * GET SPECIALS INFO
        ********************************************************************************/
        var GetAttractions = function GetAttractions() {
            fetch(site_url + 'ajax/functions.php?operation=Frontend__GetAttractions').then(function (resp) {
                return resp.json();
            }) // Transform the data into json
            .then(function (data) {

                //POPULATE SELECTORS
                PopulateSelectors(CATEGORY_SELECTOR, data.pins, "category", 'Select Category');
                // PopulateSelectors( DISTANCE_SELECTOR , data.pins, "distance", 'Select Distance');

                //POPULATE GOOGLE MAPS & ATTRACTIONS QUAD
                GetAttractionsMarkup(data.pins);
                LoadGoogleMaps(data.config, data.pins);
                matchAttractionWithMarkers();

                return categoriesData = data;
            });
        };

        /**************************************************************************************************************************************************************
        * GOOGLE MAPS
        ****************************************************************************************************************************************************************/


        var LoadGoogleMaps = function LoadGoogleMaps(config, pins) {

            var InitializeMap = function InitializeMap(lat, long) {
                var zoom = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 15;
                var styles = arguments[3];


                //SET POSITION OF MAP
                var PinPosition = new google.maps.LatLng(lat, long); //set longitude and latitude

                // option for google map object
                var myOptions = {
                    zoom: zoom,
                    center: PinPosition,
                    mapTypeId: "roadmap",
                    mapTypeControl: false,
                    styles: JSON.parse(styles),
                    scrollwheel: false,
                    disableDoubleClickZoom: false,
                    backgroundColor: '#ffffff',
                    panControl: false,
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_CENTER
                    }
                };

                // create new map make sure a DIV with id myMap exist on page
                MAP = new google.maps.Map(GOOGLE_MAPS_WRAPPER, myOptions), //generate map into div id myMap
                HOTEL_PIN = {
                    id: "hotel",
                    map: MAP,
                    title: config.hotel_name,
                    icon: config.hotel_pin.pin,
                    zIndex: 2,
                    animation: google.maps.Animation.BOUNCE,
                    position: new google.maps.LatLng(config.hotel_pin.lat, config.hotel_pin.long)
                };
                // create new marker
                new google.maps.Marker(HOTEL_PIN);

                pins.forEach(function (pin) {
                    var marker = loadMarker(pin, MAP, config.regular_pin.normal);
                    GoogleMapsMarkers.push(marker);

                    // this shows the name of each pin when hover
                    hoverPin(marker, MAP);
                });

                return $GoogleMaps = MAP;
            };

            var _config$hotel_pin = config.hotel_pin,
                lat = _config$hotel_pin.lat,
                long = _config$hotel_pin.long,
                map_zoom = config.map_zoom,
                snazzy = config.snazzy;


            InitializeMap(lat, long, map_zoom, snazzy);
        };

        // this function accepts two parameters, first: the markers, second: the target map. It shows the name of each pin when hover


        var hoverPin = function hoverPin(pin, map) {

            var nameMarkerOnHover = '<div class="nameMarker">' + pin.title + '</div>';

            var infowindow = new google.maps.InfoWindow({
                content: nameMarkerOnHover
            });

            pin.addListener('mouseover', function () {
                infowindow.open(map, pin);
                pin.setIcon(iconHover);
            });

            pin.addListener('mouseout', function () {
                infowindow.close(map, pin);
                pin.setIcon(iconDefault);
            });
        };

        /***
         * Load marker to map
         ***/


        var loadMarker = function loadMarker(pin, map_selector, icon) {
            //writes the marker to the map. pass a javascript object with the markers longitude latitude id and name so that the code can keep track of all the markers.

            var id = pin.id,
                name = pin.name,
                lat = pin.lat,
                long = pin.long;

            // create new marker

            var marker = new google.maps.Marker({
                id: id,
                map: map_selector,
                title: name,
                icon: icon,
                zIndex: 1,
                position: new google.maps.LatLng(lat, long)
            });

            marker.addListener('click', function (event) {
                return OpenLightbox(marker);
            });

            return marker;
        };

        var ExpandContent = function ExpandContent(event) {
            var el = event.target;

            var getIdBtn = el.getAttribute('id');

            if (getIdBtn === 'expand--attractions-map') {
                el.textContent = el.textContent === "Expand Map" ? "Contract Map" : "Expand Map";

                if (targetMap.style.width !== "100%") {
                    targetMap.style.cssText = "width: 100%; z-index: 3";
                } else {
                    targetMap.style.removeProperty("width");
                    targetMap.style.removeProperty("z-index");
                }
            } else if (getIdBtn === 'expand--attractions-content') {
                el.textContent = el.textContent === "Expand Attractions" ? "Contract Attractions" : "Expand Attractions";

                if (targetAtractions.style.width !== "100%") {
                    targetAtractions.style.cssText = "width: 100%; z-index: 5";
                    ATTRACTIONS_WRAPPER.classList.add('attractions-expanded');
                } else {
                    targetAtractions.style.removeProperty("width");
                    targetAtractions.style.removeProperty("z-index");
                    ATTRACTIONS_WRAPPER.classList.remove('attractions-expanded');
                }
            }
        };

        /**************************************************************************************************************************************************************
         * FILTERING DATA
         ****************************************************************************************************************************************************************/


        var FilterData = function FilterData(event) {
            event.preventDefault();
            var Filter = GetFormValues(event.target);
            GetFilterData(Filter);

            /*
            * Get values from form
            ********************************************************************************/
            function GetFormValues(el) {

                var elements = el.querySelectorAll("input, select");

                //SETTINGS
                var values = {};

                //GET VALUES
                for (var i = 0; i < elements.length; ++i) {
                    var element = elements[i];
                    var name = element.name;
                    var value = element.value;

                    if (name) {
                        values[name] = value;
                    }
                }

                return values;
            }
        };

        /*
        * Filter Data Based on what the User Selected on the Page Form
        ********************************************************************************/


        var GetFilterData = function GetFilterData(filter) {
            var attraction_categories = filter.attraction_categories,
                attraction_distance = filter.attraction_distance,
                MapBoundaries = new google.maps.LatLngBounds();

            var _attraction_distance$ = attraction_distance.split('-'),
                _attraction_distance$2 = _slicedToArray(_attraction_distance$, 2),
                distance_min = _attraction_distance$2[0],
                distance_max = _attraction_distance$2[1];

            distance_min = distance_min ? distance_min : 0;
            distance_max = distance_max ? distance_max : Number.MAX_SAFE_INTEGER;

            // let regexSearch = RegExp(`.*(?=.*${search_property_by_name.split(' ').join(')(?=.*')}).*`,'i');
            var result = categoriesData.pins.filter(function (item) {
                if (attraction_categories !== "" && item.category !== attraction_categories) return false;
                // if( search_property_by_name !== "" && !regexSearch.test(item.title) ) return false;
                if (+item.distance < distance_min || +item.distance > distance_max) return false;
                return true;
            });

            if (result.length !== 0) {
                GetAttractionsMarkup(result);
            } else {
                document.getElementById('attractions--wrapper').innerHTML = noContentfilter;
            }

            //CLEAR PINS ON MARKER
            GoogleMapsMarkers.forEach(function (pin) {
                return pin.setMap(null);
            });
            GoogleMapsMarkers = [];
            //Add Pins on Google Maps
            result.forEach(function (pin) {
                var marker = loadMarker(pin, $GoogleMaps, categoriesData.config.regular_pin.normal);
                MapBoundaries.extend(marker.getPosition());
                GoogleMapsMarkers.push(marker);

                // this shows the name of each pin when hover
                hoverPin(marker, MapBoundaries);
            });

            matchAttractionWithMarkers();

            //Fit All pins on Map
            $GoogleMaps.fitBounds(MapBoundaries);
        };

        /**************************************************************************************************************************************************************
         * LIGHTBOX
         ****************************************************************************************************************************************************************/


        var OpenLightbox = function OpenLightbox(element) {
            //Get Pin ID and then info
            var pin_id = typeof element.id === "number" ? element.id : parseInt(element.getAttribute("data-attraction")),
                PinInfo = categoriesData.pins[pin_id];

            //Get Pin Info
            var markup = getAttractionMarkUp(PinInfo);
            LIGHTBOX_WRAPPER.innerHTML = markup;
            document.querySelector("body").style.overflowY = 'hidden';
            LIGHTBOX__FULL_WRAPPER.classList.add(LIGHTBOX_OPENED__CLASS);
        };

        var CloseLigthbox = function CloseLigthbox(event) {
            if (event.keyCode === undefined && !event.target.classList.contains(LIGHTBOX_OPENED__CLASS) && event.target.id !== LIGHTBOX__CLOSE_BUTTON) return false;
            LIGHTBOX_WRAPPER.innerHTML = "";
            LIGHTBOX__FULL_WRAPPER.classList.remove(LIGHTBOX_OPENED__CLASS);
            document.querySelector("body").style.overflowY = 'visible';
        };

        /**************************************************************************************************************************************************************
         * FUNCTION CALLING AND EVENT LISTENERS
         ****************************************************************************************************************************************************************/


        // get height of window
        var windowHeight = window.innerHeight;

        // get main container, attraction container and map container
        var mainContainer = document.querySelector('.attraction-content');
        var targetMap = document.querySelector(".attraction-map--wrapper");
        var targetAtractions = document.querySelector(".categories-sidebar");

        // applying height to main content depending of window
        //mainContainer.style.height = windowHeight + 'px';

        //SETTINGS
        var categoriesData = [],
            GoogleMapsMarkers = [],
            $GoogleMaps = [];

        var CATEGORY_SELECTOR = document.querySelector("select#attraction-category"),
            DISTANCE_SELECTOR = document.querySelector("select#attraction-distance"),
            GOOGLE_MAPS_WRAPPER = document.querySelector("#attractions-map"),
            ATTRACTIONS_WRAPPER = document.querySelector("#attractions--wrapper"),
            LIGHTBOX__FULL_WRAPPER = document.querySelector("#attraction--ligthbox-wrapper"),
            LIGHTBOX__CLOSE_BUTTON = "close--lightbox",
            $LIGHTBOX__CLOSE_BUTTON = document.querySelector('#' + LIGHTBOX__CLOSE_BUTTON),
            LIGHTBOX_WRAPPER = document.querySelector("#attraction--ligthbox");var noContentfilter = '<div class=\'no-filter-content\'>\n                                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32"> <path d="M16 3c-3.472 0-6.737 1.352-9.192 3.808s-3.808 5.72-3.808 9.192c0 3.472 1.352 6.737 3.808 9.192s5.72 3.808 9.192 3.808c3.472 0 6.737-1.352 9.192-3.808s3.808-5.72 3.808-9.192c0-3.472-1.352-6.737-3.808-9.192s-5.72-3.808-9.192-3.808zM16 0v0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16s7.163-16 16-16zM14 22h4v4h-4zM14 6h4v12h-4z"/></svg>\n                                    There isn\'t any content with this filter \n                             </div>';var LIGHTBOX_OPENED__CLASS = "opened-lightbox";
        GetAttractions();
        document.querySelector("a#expand--attractions-map").addEventListener("click", function (event) {
            return ExpandContent(event);
        });
        //document.querySelector("a#expand--attractions-content").addEventListener("click", event => ExpandContent(event) );
        document.querySelector("form#attractions-filtering-form").addEventListener("submit", function (event) {
            return FilterData(event);
        });
        document.querySelector("form#attractions-filtering-form").addEventListener("reset", function (event) {
            return setTimeout(function () {
                FilterData(event);
            }, 0);
        });
        document.addEventListener('keydown', function (event) {
            return event.keyCode === 27 ? CloseLigthbox(event) : false;
        });
        LIGHTBOX__FULL_WRAPPER.addEventListener('click', function (event) {
            return CloseLigthbox(event);
        });
        $LIGHTBOX__CLOSE_BUTTON.addEventListener('click', function (event) {
            return CloseLigthbox(event);
        });
    }
})();