'use strict';

if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
function getMenu(menu_id, menu_image) {
    $.get(site_url + 'ajax/functions.php', { operation: "getMenu", menu_id: menu_id }, function (data) {
        var info = data.info;
        $('.catering-menus__intro--image img').attr('src', menu_image);
        $('.catering-menus__intro--copy--info').html('<h2>' + info.name + '</h2>' + info.description);
        $('.catering-menus__wrapper').html(data.menu);
    }, 'json');
}

(function () {

    // Remove empty <p> tags created by tinymce
    var paragraph = document.querySelectorAll('p') || [];
    paragraph.forEach(function (e) {
        return e.innerHTML.trim() === '' && e.parentNode.removeChild(e);
    });

    $('.groups li a').click(function () {
        if (!$(this).hasClass('active')) {
            $('.groups li a').removeClass('active');
            $(this).addClass('active');

            $('.groups__panel').removeClass('open');
            $('.' + $(this).attr('data-target')).addClass('open');
        }
        return false;
    });
    $('.contact-us-in-los-angeles .btnSubmit').addClass('white');

    $('.intro__right .read-more').click(function () {
        var text = $('.intro__right .read-more .txt').text();
        $('.intro__right .read-more .txt').text(text == "Read More" ? "Read Less" : "Read More");
        $('.intro__right .read-more').toggleClass('expanded');
        $('.intro__right-extra').slideToggle();
        return false;
    });
    $('.catering-menus__nav a').click(function () {
        $('.catering-menus__nav a').removeClass('active');
        $(this).addClass('active');
        var menu_image = $(this).attr('data-image');
        var menu_id = $(this).attr("data-menu");
        getMenu(menu_id, menu_image);
        return false;
    });

    $('.sym-slides').slick();

    var hash = window.location.hash;
    if (hash) {
        $('html, body').animate({ scrollTop: $(hash).offset().top - $('#header').outerHeight() }, 1000);
    }
})();